<template>
  <div
    class="login_result_page"
    :style="{ backgroundImage: 'url(' + bgPath + ')' }"
  >
    <el-button type="primary" class="start_btn" @click="startUse">开始使用</el-button>
  </div>
</template>

<script>
import bgPath from "@/assets/imgs/home.png";
import { mapState } from "vuex";
export default {
  data() {
    return {
      bgPath: bgPath
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear,
      isNew: state => state.isNew
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    })
  },
  methods: {
    startUse() {
      if (this.userInfo && !this.userInfo.province) {
        // this.$router.push({ path: "/selectCity" });
        this.$router.push({ path: "/" });
      } else {
        this.$router.push({ path: "/" });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.login_result_page {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  padding: 2rem 0;
  text-align: center;
  .start_btn.el-button {
    width: 3.4rem;
    border-radius: 100px;
    font-size: 0.35rem;
    font-weight: normal;
  }
}
</style>
